<template>
  <b-modal
    id="changePasswordModal"
    :title="t('Change Password')"
    size="md"
    hide-footer
  >
    <b-form>
      <b-row>
        <b-col>
          <validation-observer ref="changePassword">
            <b-form
              method="POST"
              autocomplete="off"
              class="auth-change-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <!-- Current password -->
              <b-form-group
                :label="t('Current Password')"
                label-for="current-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Current Password"
                  vid="current-password"
                  rules="required|min:8"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="change_password.old_password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                :label="t('New Password')"
                label-for="change-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="change-password-new"
                  rules="required|min:8|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="change-password-new"
                      v-model="change_password.password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                :label="t('Confirm New Password')"
                label-for="confirm-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm New Password"
                  vid="confirm-password-new"
                  rules="required|confirmed:change-password-new"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="change_password.password_confirmation"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="text-danger">
                <b>{{ t('Note:') }}</b> {{ t('Password must contain at least one uppercase, one lowercase, one special character and one digit') }}
              </b-form-group>
              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
              >
                {{ t('Set New Password') }}
              </b-button>

            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import {
  BModal, VBModal, BRow, BCol, BInputGroup, BFormInput, BForm, BFormGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import constants from '@/constants'
// eslint-disable-next-line import/extensions
export default {
  components: {
    BModal,
    BRow,
    BCol,
    BInputGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BInputGroupAppend,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      required,
      profile: JSON.parse(localStorage.getItem('userData')),
      change_password: {
        companyuserid: JSON.parse(localStorage.getItem('userData')).companyuserid,
        adminuserid: JSON.parse(localStorage.getItem('userData')).adminuserid,
        password: '',
        old_password: '',
        password_confirmation: '',
      },
      password1FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    if (this.profile.adminuserid) {
      this.url = 'PasswordStore/changeAdminPassword'
    } else if (this.profile.companyuserid) {
      this.url = 'PasswordStore/changePassword'
    }
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.changePassword.validate().then(success => {
        if (success) {
          store.dispatch(this.url, this.change_password).then(response => {
            // eslint-disable-next-line eqeqeq
            if (response.data.code == constants.SUCCESS) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.msg,
                  icon: 'Success',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('changePasswordModal')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.msg,
                  icon: 'Danger',
                  variant: 'danger',
                },
              })
            }
          }).catch(e => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${e.msg}`,
                icon: 'Danger',
                variant: 'danger',
                text: '',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#preview video{
  max-height: 100px;
}
#preview img{
  max-height: 100px;
}
#preview audio{
  max-height: 100px;
}
</style>
