<template>
  <b-card>
    <div class="d-flex justify-content-end mb-1">
      <b-button
        variant="primary"
        @click="redirectToChangePassword"
      >

        <feather-icon icon="RefreshCcwIcon" />
        {{ t('Change Password') }}
      </b-button>
    </div>
    <validation-observer
      ref="saveProfile"
    >
      <b-form
        enctype="multipart/form-data"
        autocomplete="off"
        @submit.prevent="saveProfile"
      >
        <b-row>
          <!-- First Name -->
          <b-col cols="6">
            <b-form-group
              :label="t('First Name')"
              label-for="vi-first-name"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                vid="vi-first-name"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-first-name"
                    v-model="form.firstname"
                    type="text"
                    :placeholder="t('First Name')"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- last Name -->
          <b-col cols="6">
            <b-form-group
              :label="t('Last Name')"
              label-for="vi-last-name"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                vid="vi-last-name"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-last-name"
                    v-model="form.lastname"
                    type="text"
                    :placeholder="t('Last Name')"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Employee Code -->
          <b-col cols="6">
            <b-form-group
              :label="t('Employee Code')"
              label-for="vi-email"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="Employee Code"
                vid="vi-email"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-email"
                    v-model="form.orange_hrms_code"
                    type="text"
                    disabled
                    :placeholder="t('Employee Code')"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Email -->
          <b-col cols="6">
            <b-form-group
              :label="t('Email')"
              label-for="vi-email"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="vi-email"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-email"
                    v-model="form.email"
                    type="text"
                    :placeholder="t('Email')"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Address 1 -->
          <b-col cols="6">
            <b-form-group
              :label="t('Address 1')"
              label-for="vi-address1"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="Address 1"
                vid="vi-address1"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MapPinIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-address1"
                    v-model="form.address1"
                    type="text"
                    :placeholder="t('Address 1')"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Address 2 -->
          <b-col cols="6">
            <b-form-group
              :label="t('Address 2')"
              label-for="vi-address2"
              class=""
            >
              <validation-provider
                #default="{ errors }"
                name="Address 2"
                vid="vi-address2"
                rules=""
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MapPinIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-address2"
                    v-model="form.address2"
                    type="text"
                    :placeholder="t('Address 2')"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- city -->
          <b-col cols="6">
            <b-form-group
              :label="t('City')"
              label-for="vi-city"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="City"
                vid="vi-city"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <vue-autosuggest
                    ref="city_name"
                    v-model="form.city_name"
                    :suggestions="cityOptions"
                    :input-props="{id: 'autosuggest__input_ajax',
                                   placeholder: t('City'),
                                   class: 'form-control',}"
                    :render-suggestion="renderSuggestion"
                    :get-suggestion-value="renderSuggestion"
                    @selected="change_autosuggest"
                    @input="suggestForm"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- zip -->
          <b-col cols="6">
            <b-form-group
              :label="t('Zip')"
              label-for="vi-zip"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="Zip"
                vid="vi-zip"
                rules="required|min:6"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MapPinIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-zip"
                    v-model="form.zip"
                    type="text"
                    :placeholder="t('Zip')"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Mobile -->
          <b-col cols="6">
            <b-form-group
              :label="t('Mobile')"
              label-for="vi-mobile"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile"
                vid="vi-mobile"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SmartphoneIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-mobile"
                    v-model="form.mobile"
                    type="text"
                    :placeholder="t('Mobile')"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="t('Profile Photo')"
              label-for="vi-profile-photo"
              class=""
            >
              <validation-provider
                #default="{ errors }"
                name="Profile Photo"
                vid="vi-profile-photo"
                rules=""
              >
                <b-input-group class="input-group-merge">
                  <b-form-file
                    id="vi-profile-photo"
                    v-model="form.profile_photo"
                    accept="image/*"
                    type="file"
                    :placeholder="t('Upload Profile Photo')"
                    :browse-text="t('Browse')"
                    @change="getprofile"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
          <b-col cols="6" />
          <b-col
            cols="6"
            class="text-center"
          >
            <div
              v-if="profile_pic"
              id="profile_photo_preview"
            >
              <img
                :src="profile_pic"
                height="200"
                alt=""
              >
            </div>
          </b-col>
          <b-col
            cols="12"
            class="text-center mt-2"
          >
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
            >
              {{ t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              class="mr-1"
              @click="$router.push('/')"
            >
              {{ t('Cancel') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <change-password-modal />
  </b-card>
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BCard, BForm, BCol, BRow, BInputGroup, BFormGroup, BInputGroupPrepend, BFormInput, BButton, BFormFile,
} from 'bootstrap-vue'
import { required } from '@validations'
import { useUtils } from '@core/libs/i18n'
import store from '@/store'
import constants from '@/constants'
import ChangePasswordModal from './ChangePasswordModal.vue'
import {
  showLoader, hideLoader, failedToast, successToast,
} from '@/utils/common'

export default {
  components: {
    BCard,
    BFormFile,
    BRow,
    BButton,
    BForm,
    VueAutosuggest,
    BCol,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    ChangePasswordModal,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      required,
      profile: JSON.parse(localStorage.getItem('userData')),
      cityOptions: [],
      url: '',
      getUserData: [],
      form: {
        mobile: '',
        email: '',
        firstname: '',
        lastname: '',
        orange_hrms_code: '',
        address2: '',
        address1: '',
        zip: '',
        city: '',
        city_name: '',
        profile_photo: [],
        profile_picture: '',
      },
      profile_pic: '/img/user.jpg',
    }
  },
  mounted() {
    if (this.profile.adminuserid) {
      this.url = 'PasswordStore/changeAdminProfile'
      this.getAdminUserById()
    } else if (this.profile.companyuserid) {
      this.url = 'PasswordStore/changeProfile'
      this.getCompanyUserById()
    }
  },
  methods: {
    async getCompanyUserById() {
      showLoader()
      await store.dispatch('CompanyuserManage/getUserById', { companyuserid: this.profile.encode_companyuserid })
        .then(response => {
          // eslint-disable-next-line eqeqeq
          if (response.data.code == constants.SUCCESS) {
            this.getUserData = response.data.data
            this.form.firstname = response.data.data.firstname
            this.form.lastname = response.data.data.lastname
            this.form.orange_hrms_code = response.data.data.orange_hrms_code
            this.form.address1 = response.data.data.address1
            this.form.address2 = response.data.data.address2
            this.form.mobile = response.data.data.mobile
            this.form.email = response.data.data.email
            this.form.zip = response.data.data.zip
            this.form.city = response.data.data.city
            this.form.companyuserid = response.data.data.companyuserid
            this.form.city_name = response.data.data.city_name
            this.form.profile_picture = response.data.data.profile_pic_url
            this.profile_pic = response.data.data.profile_pic_url
            this.$root.$emit('refreshProfilePic', this.getUserData.profile_pic_url)
          }
          hideLoader()
        }).catch(e => {
          hideLoader()
          failedToast(e.msg)
        })
    },
    async getAdminUserById() {
      showLoader()
      await store.dispatch('userManage/getUserById', { adminuserid: this.profile.encode_adminuserid })
        .then(response => {
          // eslint-disable-next-line eqeqeq
          if (response.data.code == constants.SUCCESS) {
            this.getUserData = response.data.data
            this.form.firstname = response.data.data.firstname
            this.form.lastname = response.data.data.lastname
            this.form.orange_hrms_code = response.data.data.orange_hrms_code
            this.form.address1 = response.data.data.address1
            this.form.address2 = response.data.data.address2
            this.form.mobile = response.data.data.mobile
            this.form.email = response.data.data.email
            this.form.zip = response.data.data.zip
            this.form.city = response.data.data.city
            this.form.adminuserid = response.data.data.adminuserid
            this.form.city_name = response.data.data.city_name
            this.form.profile_picture = response.data.data.profile_pic_url
            this.profile_pic = response.data.data.profile_pic_url
            this.$root.$emit('refreshProfilePic', this.getUserData.profile_pic_url)
          }
          hideLoader()
        }).catch(e => {
          hideLoader()
          failedToast(e.msg)
        })
    },
    suggestForm(val) {
      if (val.length >= 3) {
        store.dispatch('getCountryList/getCityAuto', { city: val }).then(response => {
          if (response.data.code === '200') {
            this.cityOptions.splice(0, this.cityOptions.length)
            this.cityOptions.push({ data: response.data.data })
            this.selected = null
          }
        })
      }
    },
    async saveProfile() {
      showLoader()
      await this.$refs.saveProfile.validate().then(success => {
        if (success) {
          // eslint-disable-next-line prefer-const
          let formData = new FormData()
          Object.entries(this.form).forEach(
            ([key, value]) => {
              formData.append(key, value)
            },
          )
          store.dispatch(this.url, formData).then(response => {
            if (response.data.code === constants.SUCCESS) {
              this.form.profile_photo = []
              if (this.profile.adminuserid) {
                this.getAdminUserById()
              } else if (this.profile.companyuserid) {
                this.getCompanyUserById()
              }
              successToast(response.data.msg)
            }
          }).catch(e => {
            failedToast(e.msg ? e.msg : 'Something Went Wrong!')
          })
        }
      })
      hideLoader()
    },
    redirectToChangePassword() {
      this.$bvModal.show('changePasswordModal')
    },
    getprofile(event) {
      if (event.target.files.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.form.profile_pic = event.target.files[0]
        this.profile_pic = URL.createObjectURL(event.target.files[0])
      } else this.profile_pic = '/img/user.jpg'
    },
    change_autosuggest(val) {
      this.form.city_name = val.item.city
      this.form.city = val.item.location_id
      this.$emit('getCreateUser', this.form)
    },
    renderSuggestion(suggestion) {
      return suggestion.item.city
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
